<template>
  <div>
    <div class="container pt-5 font">
      <div class="panel mt-5 pa-3">
        <div class="d-flex justify-content-center">
          <img src="@/assets/img/baml-logo.png" alt="" style="width: 200px" />
        </div>

        <form class="md-layout" @submit.prevent="handleLogin">
          <!-- <div class="panel-group mt-5">
            <input class="input" type="text" v-model="form.email" required />
            <span class="border"></span>
            <label>Email</label>
          </div> -->

          <md-snackbar
            :md-position="position"
            :md-duration="isInfinity ? Infinity : duration"
            :md-active.sync="hasError"
            md-persistent
          >
            <span>{{ errorStatus }}</span>
            <md-button class="md-primary" @click="hasError = false"
              >Ok</md-button
            >
          </md-snackbar>

          <md-field :class="getValidationClass('email')">
            <label>Email</label>
            <md-input type="email" v-model="form.email"></md-input>
            <span class="md-error" v-if="!$v.form.email.required"
              >Email is required</span
            >
            <span class="md-error" v-if="!$v.form.email.email"
              >Invalid email address</span
            >
          </md-field>

          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input type="password" v-model="form.password"></md-input>
            <span class="md-error" v-if="!$v.form.password.required"
              >Password is required</span
            >
          </md-field>
          <div class="action">
            <mdc-button
              type="submit"
              class="md-raised"
              :disabled="loading"
              :loading="loading"
              >Login</mdc-button
            >
          </div>
        </form>
        <!-- 
        <div class="panel-footer ms-5">
          <p><a href="#">Forgotten password?</a></p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
export default {
  mixins: [validationMixin],
  name: "login",
  components: {},

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
      errorStatus: "",
      hasError: false,
      position: "center",
      duration: 4000,
      isInfinity: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["signIn", "user"]),
    async handleLogin() {
      this.hasError = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          let res = await this.signIn(this.form);
          this.loading = false;
          window.location.reload();
          this.$router.push("/dashboard");
        } catch (error) {
          this.loading = false;
          this.hasError = true;
          if (!error.response) {
            // network error
            this.errorStatus = "Error: Network Error";
          } else {
            this.errorStatus = error.response.data.message;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// scss-lint:disable VendorPrefix, Indentation
@import url(https://fonts.googleapis.com/css?family=Poppins);
$bg: #616161;
$text-light: #fafafa;
$text: #212121;
$placeholder: #999;
$panel-bg: #fff;
$shadow-dark: rgba(0, 0, 0, 0.65);
$button: #f50909;
$button-hover: #f10808;
$button-active: #820505;

//MIXINS
@mixin transition($time) {
  -webkit-transition: all $time;
  transition: all $time;
}

// body {
//     background: $bg;
//     color: $text-light;
//     font-family: 'Poppins', Helvetica, Arial, Sans-Serif;
//     text-align: center;
// }

a {
  @include transition(0.4s);
  color: $button-active;
  text-decoration: none;

  &:hover {
    color: $button-hover;
    font-weight: bold;
  }
}

.panel {
  @include transition(0.7s);
  background: $panel-bg;
  border-radius: 5px;
  /* box-shadow: 0 5px 20px $shadow-dark; */
  color: $text;
  // height: 300px;
  left: 0;
  margin: 0 auto 150px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  right: 0;
  top: 50px;
  width: 460px;
  z-index: 200;
  max-width: 100%;
  /* &:hover {
    @include transition(0.7s);
    border-radius: 35px 35px 35px 5px;
    box-shadow: 0 2px 5px $shadow-dark;
  } */

  .action {
    display: flex;
    width: 100%;
    justify-content: end;
  }
}

.panel-group {
  margin-bottom: 35px;
  margin-left: 40px;
  position: relative;
}

.input {
  border: 0;
  /* border-bottom: 1px solid $text; */
  display: block;
  font-size: 1em;
  padding: 10px 10px 10px 5px;
  width: 400px;
  margin: auto;

  &:focus {
    outline: 0;
  }
}

label {
  @include transition(0.2s);
  color: $placeholder;
  font-size: 14px;
  font-weight: normal;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}

//animate label when input is in focus
.input:focus ~ label,
.input:valid ~ label {
  color: $button-hover;
  font-size: 0.75em;
  font-weight: bold;
  top: -15px;
}

//animate bottom border when input is in focus*/
.border {
  display: block;
  position: relative;
  width: 315px;
}

.border::before,
.border::after {
  @include transition(0.5s);
  background: $button-active;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  width: 0;
}

.border::before {
  left: 50%;
}

.border::after {
  right: 50%;
}

//border width when clicked*/
.input:focus ~ .border::before,
.input:focus ~ .border::after {
  width: 50%;
}

.panel-footer {
  font-size: 0.75em;
}

footer {
  bottom: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.follow {
  margin: 0 auto;
}
</style>
